.app-table {
    position: relative;
    z-index: 0;
    max-height: 100px;
    width: 100%;
}

.app-table .fixed-cell {
    right: 0px;
    /* background-color: #f6f6f6; */
    position: sticky;
}


.app-table .fixed-cell.left {
    left: 0px;
    padding-left: 1rem;
    /* z-index: 5; */
}

.app-table th {
    font-size: 14px !important;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    letter-spacing: 1px;
    font-weight: bolder !important;
    color: black;
    vertical-align: top;
    text-transform: capitalize !important;
    text-align: center !important;
    /* background-color: white; */
}

.app-table th.fixed-cell {
    z-index: 5;
}

.app-table tbody tr {
    color: black;
    padding-left: 0px !important;
    padding-right: 0px !important;
    position: relative;
}

.app-table tbody tr.deactive {
    color: #9ea1a4;
}

/* .app-table tbody tr:hover {
    background-color: #e9e9ef;
} */

/* .app-table tbody tr:hover .fixed-cell {
    background-color: #e9e9ef;
} */

.app-table tbody td {
    /* font-size: 14px; */
    /* text-align: center !important; */
    /* letter-spacing: 1px; */
    vertical-align: top;
}
