
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

#feedbacklabelspan {
  position: fixed;
  right: 400px;
  z-index: 0;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.btn-file {
  position: relative;
  overflow: hidden;
  transition: background-color .5s;
}

.btn-file:hover {
  background-color: #919191 !important;
}
.btn-file input[type=file] {
  position: absolute;
  font-size: 100px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  background: white;
  cursor: inherit;
  display: block;
}
.deleteIcon {
  opacity:0.7;
  transition-duration: 0.25s;
  transition: opacity 0.25s;
  -moz-transition: opacity 0.25s;
  -webkit-transition: opacity 0.25s;
}
.deleteIcon:hover {
  opacity:1;
}
.reduceOpacityOnhover{
  opacity:1;
  transition-duration: 0.5s;
  transition: opacity 0.5s;
  -moz-transition: opacity 0.5s;
  -webkit-transition: opacity 0.5s;
}
.reduceOpacityOnhover:hover{
  opacity:0.6;
  text-shadow: none;
}

/* .dataTables_filter input {
  color: white;
  background-color: rgb(32, 43, 96);
  
} */

/* div.dataTables_length select {
  color: white !important;
  background-color: rgb(32, 43, 96) !important;
  background-color: black !important; 
} */

.custom-row-style {
  background-color: grey !important;
  /* font-weight: bold; */
}
.custom-row-style1 {
  background-color: linear-gradient(65deg, rgb(16, 24, 65), rgb(32, 43, 96)) !important;
}

.selectedRow {
  background-color: grey !important;
  
}

/* 
.dataTables_wrapper .dataTables_paginate .paginate_button {
  color: #000 !important;
  border: 1px solid rgb(221, 221, 221)!important;
  border-radius: 20px !important;
  background-color: rgb(221, 221, 221)!important;
  background: -webkit-linear-gradient(top, rgb(221, 221, 221) 0%, rgb(221, 221, 221) 100%)!important;
  background: -moz-linear-gradient(top, rgb(221, 221, 221) 0%, rgb(221, 221, 221) 100%)!important;
  background: -ms-linear-gradient(top, rgb(221, 221, 221) 0%, rgb(221, 221, 221) 100%) !important;
  background: -o-linear-gradient(top, rgb(221, 221, 221) 0%, rgb(221, 221, 221) 100%)!important;
  background: linear-gradient(to bottom, rgb(221, 221, 221) 0%, rgb(221, 221, 221) 100%)!important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  color: #000 !important;
  border: 1px solid rgba(221, 221, 221, 0.546)!important;
  border-radius: 20px !important;
  background-color: rgba(221, 221, 221, 0.546)!important;
  background: -webkit-linear-gradient(top, rgba(221, 221, 221, 0.546) 0%, rgba(221, 221, 221, 0.546) 100%)!important;
  background: -moz-linear-gradient(top, rgba(221, 221, 221, 0.546) 0%, rgba(221, 221, 221, 0.546) 100%)!important;
  background: -ms-linear-gradient(top, rgba(221, 221, 221, 0.546) 0%, rgba(221, 221, 221, 0.546) 100%) !important;
  background: -o-linear-gradient(top, rgba(221, 221, 221, 0.546) 0%, rgba(221, 221, 221, 0.546) 100%)!important;
  background: linear-gradient(to bottom, rgba(221, 221, 221, 0.546) 0%, rgba(221, 221, 221, 0.546) 100%)!important;
} */

/* 
.dataTables_wrapper .dataTables_paginate .paginate_button.current {
  border: 1px solid gray!important;
  border-radius: 20px !important;
  
} */




/* Active pagination button styles
.dataTables_wrapper .paginate_button .current:hover {
  color: #fff;
  background-color: #5cb85c;
  border: none;
} */

.card-body {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.015s ease-in-out; /* Adjust the duration and easing as needed */
}

/* Expand the accordion body when it's open */
.card-body.show {
  max-height: 1000px; /* A large enough value to accommodate the content */
}
