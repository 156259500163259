body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#StackBar {
  width: 400px;
  height: 490px;
  border-color: blue;
  /* background-color: rgb(230, 230, 230); */
}

.progress-bar-marker {
  background-image: linear-gradient(
    90deg,
    rgba(225, 225, 225, 0.1) 97%,
    #4babed 3%
  );
}

.bi.bi-diagram .bi-link-canvas-layer .bi-diagram-link .bi-link-path {
  stroke-width: 2px !important;
}

.bi.bi-diagram .bi-link-canvas-layer .bi-diagram-link .bi-link-path {
  stroke-width: 2px !important;
}

/* to disable toggle-down-button */
.dropdown-toggle::after {
  display: none !important;
}

/* to disable focus border color in nav icons */
.btn:focus {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}

.bi.bi-diagram .bi-link-canvas-layer .bi-diagram-link:hover .bi-link-path {
  stroke: #4babed !important;
  stroke-dasharray: 10, 2;
  animation: BiDashSegmentAnimation 1s linear infinite;
}

.bi.bi-diagram .bi.bi-diagram-node {
  z-index: unset !important;
}

/* .css-1qgma8u-MuiButtonBase-root-MuiTableSortLabel-root.Mui-active{
  color: white !important;
} */

.css-1qgma8u-MuiButtonBase-root-MuiTableSortLabel-root.Mui-active
  .MuiTableSortLabel-icon {
  opacity: 1;
  color: gray !important;
}

/* .css-1qgma8u-MuiButtonBase-root-MuiTableSortLabel-root:hover {
  color: white !important;
}  */
.css-qc6sy-singleValue {
  color: unset !important;
}

.my-custom-select__single-value {
  color: unset !important;
}

@media only screen and (max-width: 1026px) {
  .bi-diagram-link {
    display: none;
  }
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  /* background: rgb(36, 36, 36);  */
  background: transparent;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(189, 188, 188);
  /*old color : #888*/
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
  border-radius: 10px;
}

.scroll {
  scrollbar-color: rgb(255, 255, 255, 20%) transparent;
  scrollbar-width: thin !important;
}

.lg-screen-margin {
  margin-left: 70px;
}

@media screen and (max-width: 701px) {
  .lg-screen-margin {
    margin-left: 0px;
  }

  .sm-screen-font {
    font-size: 0.75rem;
  }
}

.dropdown-menu {
  background-color: transparent !important;
  border: none !important;
}

.dropdown-item {
  color: none;
}

.userCard:hover {
  /* opacity: 0.5; */
  box-shadow: 0px 0px 10px rgb(255, 255, 255, 0.1);
}

.userCard {
  opacity: 1;
  transition: opacity 0.4s;
  /* box-shadow: 0px 0px 10px rgb(0, 5, 36); */
}

.logsNotiCard:hover {
  opacity: 0.5;
  /* box-shadow: 0px 0px 10px rgb(255, 255, 255, 0.1); */
}

.logsNotiCard {
  opacity: 1;
  transition: opacity 0.4s;
  /* box-shadow: 0px 0px 10px rgb(0, 5, 36); */
}

.editShow {
  opacity: 0;
}

.userCard:hover .editShow {
  transition: none;
  opacity: 1 !important;
}

.slider {
  width: 400px;
  transition: all 0.2s;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.modal {
  top: 50px !important;
}

.btn2:hover {
  color: white !important;
}

/* customer support */

.zsFontClass {
  color: #fff;
  font-family: Arial;
  font-size: 13px;
}

#zohoSupportWebToCase textarea,
#zohoSupportWebToCase input[type="text"],
#zohoSupportWebToCase select,
.wb_common {
  width: 280px;
}

#zohoSupportWebToCase td {
  padding: 11px 5px;
}

#zohoSupportWebToCase textarea,
#zohoSupportWebToCase input[type="text"],
#zohoSupportWebToCase select {
  border: 1px solid #ddd;
  padding: 3px 5px;
  border-radius: 3px;
}

#zohoSupportWebToCase select {
  box-sizing: unset;
}

#zohoSupportWebToCase .wb_selectDate {
  width: auto;
}

#zohoSupportWebToCase input.wb_cusInput {
  width: 108px;
}

.wb_FtCon {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 15px;
  padding-left: 10px;
}

.wb_logoCon {
  display: flex;
  margin-left: 5px;
}

.wb_logo {
  max-width: 16px;
  max-height: 16px;
}

.zsFormClass {
  /* background-color: #FFFFFF; */
  width: 600px;
}

.manfieldbdr {
  border-left: 2px solid crimson !important;
}

.hleft {
  text-align: left;
}

input[type="file"]::-webkit-file-upload-button {
  cursor: pointer;
}

.wtcsepcode {
  margin: 0px 15px;
  color: #aaa;
  float: left;
}

.wtccloudattach {
  float: left;
  color: #00a3fe !important;
  cursor: pointer;
  text-decoration: none !important;
}

.wtccloudattach:hover {
  text-decoration: none !important;
}

.wtcuploadinput {
  cursor: pointer;
  float: left;
  width: 62px;
  margin-top: -20px;
  opacity: 0;
  clear: both;
}

.wtcuploadfile {
  float: left;
  color: #00a3fe;
}

.filenamecls {
  margin-right: 15px;
  float: left;
  margin-top: 5px;
}

.clboth {
  clear: both;
}

#zsFileBrowseAttachments {
  clear: both;
  margin: 5px 0px 10px;
}

.zsFontClass {
  vertical-align: top;
}

#tooltip-zc {
  font: normal 12px Arial, Helvetica, sans-serif;
  line-height: 18px;
  position: absolute;
  padding: 8px;
  margin: 20px 0 0;
  background: #fff;
  border: 1px solid #528dd1;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  color: #eee;
  -webkit-box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.2);
  z-index: 10000;
  color: #777;
}

.wtcmanfield {
  color: #f00;
  font-size: 16px;
  position: relative;
  top: 2px;
  left: 1px;
}

#zsCloudAttachmentIframe {
  width: 100%;
  height: 100%;
  z-index: 99999 !important;
  position: fixed;
  left: 0px;
  top: 0px;
  border-style: none;
  display: none;
  background-color: #fff;
}

.wtchelpinfo {
  background-position: -246px -485px;
  width: 15px;
  height: 15px;
  display: inline-block;
  position: relative;
  top: 2px;
  background-image: url("https://css.zohostatic.com/support/6229071/images/zs-mpro.png");
}

.zsMaxSizeMessage {
  font-size: 13px;
}

.submitBtn {
  color: black;
}

.carousel-indicators button {
  visibility: hidden;
}

.table-container {
  position: relative;
  max-height: 400px;
  /* Add a maximum height or adjust as needed */
  overflow: auto;
}

.sticky-column {
  position: sticky;
  left: 0;
  z-index: 2000;
  background-color: #fff;
}

.float-column {
  background-color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  width: 120px;
  /* Set the width of the floated column */
  z-index: 2;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}

.preview > .react-pdf__Page__canvas {
  height: 90px !important;
}

.textLayer,
.annotationLayer {
  display: none;
}

.react-pdf__Page {
  background-color: unset !important;
}

/* for react select multi select box */

/* .react-select__input{
  color: #fff !important;
  color: black !important;
} */

/* for multiselect select box  */

:root {
  --background-color-light: #fff;
  --background-color-dark: #222b5d;
  --text-color-light: #000;
  --text-color-dark: #c6c6c6;
  --control-bg-color-light: #f6f6f6;
  --control-bg-color-dark: #222b5d;
  --control-border-color: #5e5e5e;
}

.rmsc {
  --rmsc-main: #4285f4;
  --rmsc-hover: #f1f3f5;
  --rmsc-selected: #e2e6ea;
  --rmsc-border: #ccc;
  --rmsc-gray: #aaa;
  --rmsc-bg: #fff;
  --rmsc-p: 10px;
  --rmsc-radius: 4px;
  --rmsc-h: 38px;
}

/* Light mode styles */
.filter-bar-light .rmsc .dropdown-container {
  background-color: #f6f6f6 !important;
  color: var(--text-color-light) !important;
  border: 0.3px solid #cccccc !important;
}

/* Dark mode styles */
.filter-bar-dark .rmsc .dropdown-container {
  background-color: var(--background-color-dark) !important;
  color: var(--text-color-dark) !important;
  border: 0.3px solid #5e5e5e !important;
}

.filter-bar-dark .rmsc .options {
  background-color: var(--background-color-dark) !important;
  color: var(--text-color-dark) !important;
}

.filter-bar-dark .rmsc .options li {
  background-color: var(--background-color-dark) !important;
  color: var(--text-color-dark) !important;
}

.filter-bar-dark .rmsc .select-item {
  background-color: var(--background-color-light) !important;
  color: #000 !important;
}

.filter-bar-dark .rmsc .select-item:hover {
  background-color: #eaeaea !important;
}

.filter-bar-light .rmsc .select-item {
  background-color: var(--background-color-light) !important;
}

.filter-bar-light .rmsc .select-item:hover {
  background-color: #eaeaea !important;
}

/* Shared styles for control */
.filter-bar-shared .rmsc .dropdown-container .react-multi-select-component {
  background-color: var(--control-bg-color-light);
  color: rgb(81, 78, 78);
  border: 0.5px solid var(--control-border-color);
}

/* Dark mode styles for control */
.filter-bar-dark
  .filter-bar-shared
  .rmsc
  .dropdown-container
  .react-multi-select-component {
  background-color: var(--control-bg-color-dark);
}

.filter-bar-light
  .filter-bar-shared
  .rmsc
  .dropdown-container
  .react-multi-select-component {
  background-color: var(--control-bg-color-light);
}

/* landing container css */

@media (min-width: 200px) and (max-width: 290px) {
  .welcome {
    font-size: 14px;
  }
  .appselect {
    font-size: 12px;
  }
  .assetCategorization {
    font-size: medium;
  }
  .fleetAggregate {
    font-size: 14px;
  }
  .individualAsset {
    font-size: 14px;
  }
  .vTypeFont {
    font-size: 12px;
  }
  .clist {
    max-width: 220px !important;
    margin-bottom: 3em !important;
  }

  .ismobile {
    max-width: 280px !important;
  }
  .ispotmobile {
    min-width: 180px;
    max-width: 220px;
    margin-left: 16% !important;
  }
  .companylistmobile {
    max-width: 280px;
  }
  .clistwithchilcompany {
    max-width: 180px !important;
  }
}

@media (min-width: 300px) and (max-width: 360px) {
  .welcome {
    font-size: 14px;
  }
  .appselect {
    font-size: 12px;
  }
  .assetCategorization {
    font-size: medium;
  }
  .fleetAggregate {
    font-size: 14px;
  }
  .individualAsset {
    font-size: 14px;
  }
  .vTypeFont {
    font-size: 12px;
  }
  .clist {
    max-width: 300px !important;
    margin-bottom: 3em !important;
  }
  .ismobile {
    max-width: 340px !important;
  }
  .ispotmobile {
    min-width: 180px;
    max-width: 220px;
    margin-left: 20% !important;
  }
  .companylistmobile {
    max-width: 350px;
  }
  .clistwithchilcompany {
    max-width: 260px !important;
  }
}

@media (min-width: 361px) and (max-width: 400px) {
  .welcome {
    font-size: 14px;
  }
  .appselect {
    font-size: 12px;
  }
  .assetCategorization {
    font-size: medium;
  }
  .fleetAggregate {
    font-size: 14px;
  }
  .individualAsset {
    font-size: 14px;
  }
  .vTypeFont {
    font-size: 12px;
  }
  .ismobile {
    max-width: 380px;
  }
  .ispotmobile {
    min-width: 180px;
    max-width: 220px;
    margin-left: 20% !important;
  }
  .companylistmobile {
    max-width: 380px;
  }
  .clwithchild {
    max-width: 300px !important;
    overflow: hidden;
  }
  .clist {
    max-width: 300px !important;
    margin-bottom: 3em !important;
  }
  .clistwithchilcompany {
    max-width: 280px !important;
  }
  .companylistdesktop {
    margin-right: 2em !important;
  }
}

@media (min-width: 401px) and (max-width: 420px) {
  .welcome {
    font-size: 14px;
  }
  .appselect {
    font-size: 12px;
  }
  .assetCategorization {
    font-size: medium;
  }
  .fleetAggregate {
    font-size: 14px;
  }
  .individualAsset {
    font-size: 14px;
  }
  .vTypeFont {
    font-size: 12px;
  }
  .ismobile {
    max-width: 380px;
    margin-left: 0.5% !important;
  }
  .ispotmobile {
    min-width: 180px;
    max-width: 220px;
    margin-left: 20% !important;
  }
  .companylistmobile {
    max-width: 380px;
  }
  .clwithchild {
    max-width: 300px !important;
    overflow: hidden;
  }
  .clist {
    max-width: 300px !important;
    margin-bottom: 3em !important;
  }
  .clistwithchilcompany {
    max-width: 280px !important;
  }
  .companylistdesktop {
    margin-right: 2em !important;
  }
}

@media (min-width: 540px) and (max-width: 550px) {
  .welcome {
    font-size: 14px;
  }
  .appselect {
    font-size: 12px;
  }
  .assetCategorization {
    font-size: medium;
  }
  .fleetAggregate {
    font-size: 14px;
  }
  .individualAsset {
    font-size: 14px;
  }
  .vTypeFont {
    font-size: 12px;
  }
  .ismobile {
    max-width: 380px;
    margin-left: 13% !important;
  }
  .ispotmobile {
    min-width: 180px;
    max-width: 220px;
    margin-left: 20% !important;
  }
  .companylistmobile {
    max-width: 380px;
  }
  .clwithchild {
    max-width: 300px !important;
    overflow: hidden;
  }
  .clist {
    max-width: 300px !important;
    margin-bottom: 3em !important;
  }
  .clistwithchilcompany {
    max-width: 280px !important;
  }
  .companylistdesktop {
    margin-right: 2em !important;
  }
}

@media (min-width: 560px) and (max-width: 800px) {
  .welcome {
    font-size: 22px;
  }
  .appselect {
    font-size: 20px;
  }
  .assetCategorization {
    font-size: medium;
  }
  .fleetAggregate {
    font-size: 16px;
  }
  .individualAsset {
    font-size: 16px;
  }
  .vTypeFont {
    font-size: 14px;
  }
  .clist {
    max-width: 400px !important;
    margin-bottom: 3em !important;
  }
  .ismobile {
    max-width: 600px;
    margin-left: 9% !important;
  }
  .ispotmobile {
    min-width: 200px;
    max-width: 260px;
    margin-left: 28% !important;
  }
  .companylistmobile {
    max-width: 580px;
  }
  .companylistdesktop {
    margin-right: 2em !important;
  }
}

@media (min-width: 810px) and (max-width: 890px) {
  .welcome {
    font-size: 22px;
  }
  .appselect {
    font-size: 20px;
  }
  .assetCategorization {
    font-size: medium;
  }
  .fleetAggregate {
    font-size: 16px;
  }
  .individualAsset {
    font-size: 16px;
  }
  .vTypeFont {
    font-size: 14px;
  }
  .clist {
    max-width: 400px !important;
    margin-bottom: 3em !important;
  }
  .ismobile {
    max-width: 600px;
    margin-left: 11% !important;
  }
  .ispotmobile {
    min-width: 200px;
    max-width: 260px;
    margin-left: 28% !important;
  }
  .companylistmobile {
    max-width: 580px;
  }
  .companylistdesktop {
    margin-right: 2em !important;
  }
}

@media (min-width: 900px) and (max-width: 1000px) {
  .welcome {
    font-size: 25px;
  }
  .appselect {
    font-size: 20px;
  }
  .assetCategorization {
    font-size: medium;
  }
  .fleetAggregate {
    font-size: 16px;
  }
  .individualAsset {
    font-size: 16px;
  }
  .vTypeFont {
    font-size: 14px;
  }
  .clist {
    max-width: 400px !important;
    margin-bottom: 3em !important;
  }
  .ismobile {
    max-width: 600px;
    margin-left: 15% !important;
  }
  .ispotmobile {
    min-width: 200px;
    max-width: 260px;
    margin-left: 28% !important;
  }
  .companylistmobile {
    max-width: 600px;
  }
  .companylistdesktop {
    margin-right: 2em !important;
  }
}

@media (min-width: 1000px) and (max-width: 2000px) {
  .welcome {
    font-size: 26px;
  }
  .appselect {
    font-size: 24px;
  }
  .clistonly {
    max-width: 500px !important;
    margin-bottom: 3em !important;
  }
  .landingdesktop {
    max-width: 100% !important;
  }
  .companylistdesktop {
    margin-right: 5em !important;
    min-width: 50% !important;
  }
  .ispotdesktop {
    max-width: 30% !important;
    min-width: 15% !important;
  }
}

@media (min-width: 2000px) and (max-width: 10000px) {
  .welcome {
    font-size: 26px;
  }
  .appselect {
    font-size: 24px;
  }
  .clistonly {
    max-width: 500px !important;
    margin-bottom: 3em !important;
  }
  .landingdesktop {
    max-width: 100% !important;
  }
  .companylistdesktop {
    margin-right: 5em !important;
    min-width: 50% !important;
  }
  .ispotdesktop {
    max-width: 30% !important;
    min-width: 15% !important;
  }
}
.app-table {
  text-align: start !important;
}

/* In a CSS file or style block */
.custom-modal-width {
  max-width: 1200px !important; /* Set the desired width here */
  width: 100%;
  padding-bottom: 4em !important;
}

/* If you want to center the modal horizontally, you can use the following styles as well */
.custom-modal-width .modal-dialog {
  margin: 0 auto;
}

.custom-width-col {
  width: 20% !important;
}

.dataTables_scrollHeadInner {
  width: 100% !important;
}

.dataTables_wrapper.no-footer div.dataTables_scrollHead table.dataTable,
.dataTables_wrapper.no-footer div.dataTables_scrollBody > table {
  width: 100% !important;
}

.table.dataTable thead th,
table.dataTable thead td {
  width: 20% !important;
}

.btn-custom {
  width: 120px;
  height: 37.33px !important;
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
  border: 1px solid #76a7dc !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  color: #222b5d !important;
  background-color: #76a7dc !important;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s !important;
}

.btn-custom:hover {
  background-color: #76a7dc !important;
  color: #222b5d !important;
  border-color: #76a7dc !important;
}

.btn-custom-inactive {
  width: 120px;
  height: 37.33px !important;
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
  border: 1px solid #76a7dc !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  color: #76a7dc !important;
  background-color: transparent !important;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s !important;
}

.btn-custom-inactive:hover {
  background-color: #76a7dc !important;
  color: #222b5d !important;
  border-color: #76a7dc !important;
}

.btn-custom-light {
  width: 120px;
  height: 37.33px !important;
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
  border: 1px solid #198754 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  color: #fff !important;
  background-color: #198754 !important;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s !important;
}

.btn-custom-light:hover {
  background-color: #198754 !important;
  color: #fff !important;
  border-color: #198754 !important;
}

.btn-custom-light-inactive {
  width: 120px;
  height: 37.33px !important;
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
  border: 1px solid #198754 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  color: #198754 !important;
  background-color: transparent !important;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s !important;
}

.btn-custom-light-inactive:hover {
  background-color: #198754 !important;
  color: #fff !important;
  border-color: #198754 !important;
}

.btn-custom-table1 {
  width: 120px;
  height: 37.33px !important;
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
  border: 1px solid #76a7dc !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  color: #222b5d !important;
  background-color: #76a7dc !important;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s !important;
}

.btn-custom-table1:hover {
  background-color: #76a7dc !important;
  color: #222b5d !important;
  border-color: #76a7dc !important;
}

.btn-custom-table1-inactive {
  width: 120px;
  height: 37.33px !important;
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
  border: 1px solid #76a7dc !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  color: #76a7dc !important;
  background-color: transparent !important;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s !important;
}

.btn-custom-table1-inactive:hover {
  background-color: #76a7dc !important;
  color: #222b5d !important;
  border-color: #76a7dc !important;
}

.btn-custom-table1-light {
  width: 120px;
  height: 37.33px !important;
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
  border: 1px solid #198754 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  color: #fff !important;
  background-color: #198754 !important;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s !important;
}

.btn-custom-table1-light:hover {
  background-color: #198754 !important;
  color: #fff !important;
  border-color: #198754 !important;
}

.btn-custom-table1-light-inactive {
  width: 120px;
  height: 37.33px !important;
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
  border: 1px solid #198754 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  color: #198754 !important;
  background-color: transparent !important;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s !important;
}

.btn-custom-table1-light-inactive:hover {
  background-color: #198754 !important;
  color: #fff !important;
  border-color: #198754 !important;
}

.btn-custom-table2 {
  width: 120px;
  height: 37.33px !important;
  border: 1px solid #76a7dc !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  color: #222b5d !important;
  background-color: #76a7dc !important;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s !important;
}

.btn-custom-table2:hover {
  background-color: #76a7dc !important;
  color: #222b5d !important;
  border-color: #76a7dc !important;
}

.btn-custom-table2-inactive {
  width: 120px;
  height: 37.33px !important;
  border: 1px solid #76a7dc !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  color: #76a7dc !important;
  background-color: transparent !important;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s !important;
}

.btn-custom-table2-inactive:hover {
  background-color: #76a7dc !important;
  color: #222b5d !important;
  border-color: #76a7dc !important;
}

.btn-custom-table2-light {
  width: 120px;
  height: 37.33px !important;
  border: 1px solid #198754 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  color: #fff !important;
  background-color: #198754 !important;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s !important;
}

.btn-custom-table2-light:hover {
  background-color: #198754 !important;
  color: #fff !important;
  border-color: #198754 !important;
}

.btn-custom-table2-light-inactive {
  width: 120px;
  height: 37.33px !important;
  border: 1px solid #198754 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  color: #198754 !important;
  background-color: transparent !important;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s !important;
}

.btn-custom-table2-light-inactive:hover {
  background-color: #198754 !important;
  color: #fff !important;
  border-color: #198754 !important;
}

.btn-custom-table3 {
  width: 120px;
  height: 37.33px !important;
  border: 1px solid #76a7dc !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  color: #222b5d !important;
  background-color: #76a7dc !important;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s !important;
}

.btn-custom-table3:hover {
  background-color: #76a7dc !important;
  color: #222b5d !important;
  border-color: #76a7dc !important;
}

.btn-custom-table3-inactive {
  width: 120px;
  height: 37.33px !important;
  border: 1px solid #76a7dc !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  color: #76a7dc !important;
  background-color: transparent !important;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s !important;
}

.btn-custom-table3-inactive:hover {
  background-color: #76a7dc !important;
  color: #222b5d !important;
  border-color: #76a7dc !important;
}

.btn-custom-table3-light {
  width: 120px;
  height: 37.33px !important;
  border: 1px solid #198754 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  color: #fff !important;
  background-color: #198754 !important;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s !important;
}

.btn-custom-table3-light:hover {
  background-color: #198754 !important;
  color: #fff !important;
  border-color: #198754 !important;
}

.btn-custom-table3-light-inactive {
  width: 120px;
  height: 37.33px !important;
  border: 1px solid #198754 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  color: #198754 !important;
  background-color: transparent !important;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s !important;
}

.btn-custom-table3-light-inactive:hover {
  background-color: #198754 !important;
  color: #fff !important;
  border-color: #198754 !important;
}

.btn-custom-table4 {
  width: 120px;
  height: 37.33px !important;
  border: 1px solid #76a7dc !important;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  color: #222b5d !important;
  background-color: #76a7dc !important;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s !important;
}

.btn-custom-table4:hover {
  background-color: #76a7dc !important;
  color: #222b5d !important;
  border-color: #76a7dc !important;
}

.btn-custom-table4-inactive {
  width: 120px;
  height: 37.33px !important;
  border: 1px solid #76a7dc !important;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  color: #76a7dc !important;
  background-color: transparent !important;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s !important;
}

.btn-custom-table4-inactive:hover {
  background-color: #76a7dc !important;
  color: #222b5d !important;
  border-color: #76a7dc !important;
}

.btn-custom-table4-light {
  width: 120px;
  height: 37.33px !important;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
  border: 1px solid #198754 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  color: #fff !important;
  background-color: #198754 !important;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s !important;
}

.btn-custom-table4-light:hover {
  background-color: #198754 !important;
  color: #fff !important;
  border-color: #198754 !important;
}

.btn-custom-table4-light-inactive {
  width: 120px;
  height: 37.33px !important;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
  border: 1px solid #198754 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  color: #198754 !important;
  background-color: transparent !important;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s !important;
}

.btn-custom-table4-light-inactive:hover {
  background-color: #198754 !important;
  color: #fff !important;
  border-color: #198754 !important;
}

.btn-custom-upload {
  height: 37.33px !important;
  border-top-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
  border: 1px solid #76a7dc !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  color: #222b5d !important;
  background-color: #76a7dc !important;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s !important;
}

.btn-custom-upload:hover {
  background-color: #76a7dc !important;
  color: #222b5d !important;
  border-color: #76a7dc !important;
}

.btn-custom-upload-light {
  height: 37.33px !important;
  border-top-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
  border: 1px solid #198754 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  color: #fff !important;
  background-color: #198754 !important;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s !important;
}

.btn-custom-upload-light:hover {
  background-color: #198754 !important;
  color: #fff !important;
  border-color: #198754 !important;
}

.infocircleatDataimport {
  color: #76a7dc !important;
}

.infocircleatDataimport-light {
  color: #198754 !important;
}

/* unit-level */

.btn-custom-individual-search {
  font-size: 14px !important;
  width: 80px;
  /* height: 30.49px !important; */
  border-color: #76a7dc !important;
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
  border: 1px solid #76a7dc !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  color: #222b5d !important;
  background-color: #76a7dc !important;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s !important;
}

.btn-custom-individual-light-search {
  font-size: 14px !important;
  width: 80px;
  /* height: 30.49px !important; */
  border-color: #198754 !important;
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
  border: 1px solid #198754 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  color: #fff !important;
  background-color: #198754 !important;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s !important;
}

.btn-custom-individual-scope1 {
  z-index: 0;
  font-size: 14px !important;
  width: 90px;
  height: 35px !important;
  border-top-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
  /* border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important; */
  border-bottom: 1px solid #c6c6c6;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  color: #c6c6c6 !important;
  background: linear-gradient(65deg, #101841, #202b60);
  transition: background-color 0.3s, color 0.3s, border-color 0.3s !important;
}

.btn-custom-individual-scope1-inactive {
  z-index: 0;
  font-size: 14px !important;
  width: 90px;
  height: 35px !important;
  border-color: #76a7dc !important;
  border-top-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
  /* border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important; */
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  /* color: rgba(255, 255, 255, 0.3) !important;
  background:  #4F5C73 !important; */
  transition: background-color 0.3s, color 0.3s, border-color 0.3s !important;
}

.btn-custom-individual-scope1-light {
  z-index: 0;
  font-size: 14px !important;
  width: 90px;
  height: 35px !important;
  border-top-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
  border-bottom: 1px solid #c6c6c6;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  color: #ffffff !important;
  background: #198754 !important;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s !important;
}

.btn-custom-individual-scope1-light-inactive {
  z-index: 0;
  font-size: 14px !important;
  width: 90px;
  height: 35px !important;
  border-top-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  color: #fff !important;
  background-color: #c6c6c6 !important;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s !important;
}

.btn-custom-individual-year {
  cursor: pointer;
  font-size: 14px !important;
  width: 80px;
  height: 35px !important;
  border: 1px solid #c6c6c6;
  border-radius: 8px;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  color: #c6c6c6 !important;
  background: linear-gradient(65deg, #101841, #202b60);
  transition: background-color 0.3s, color 0.3s, border-color 0.3s !important;
}

.btn-custom-individual-year-inactive {
  cursor: pointer;
  font-size: 14px !important;
  width: 80px;
  height: 35px !important;
  display: flex !important;
  border-radius: 8px;
  align-items: center !important;
  justify-content: center !important;
  color: rgba(255, 255, 255, 0.3) !important;
  /* color: #c6c6c6 !important; */
  /* background:linear-gradient(65deg,#101841,#202b60); */
  transition: background-color 0.3s, color 0.3s, border-color 0.3s !important;
}

.btn-custom-individual-year-light {
  cursor: pointer;
  font-size: 14px !important;
  width: 80px;
  height: 35px !important;
  border: 1px solid #c6c6c6;
  border-radius: 8px;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  color: #ffffff !important;
  background-color: #198754 !important;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s !important;
}

.btn-custom-individual-year-light-inactive {
  cursor: pointer;
  font-size: 14px !important;
  width: 80px;
  height: 35px !important;
  display: flex !important;
  align-items: center !important;
  border-radius: 8px;
  justify-content: center !important;
  color: #c6c6c6 !important;
  /* background-color: #c6c6c6 !important; */
  transition: background-color 0.3s, color 0.3s, border-color 0.3s !important;
}

.btn-custom-individual-scope2 {
  font-size: 14px !important;
  border-radius: 0;
  margin-left: 2px;
  margin-right: 2px;
  width: 90px;
  height: 35px !important;
  border-top-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
  /* border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important; */
  border-bottom: 1px solid #c6c6c6;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  color: #c6c6c6 !important;
  background: linear-gradient(65deg, #101841, #202b60);
  transition: background-color 0.3s, color 0.3s, border-color 0.3s !important;
}

.btn-custom-individual-scope2-inactive {
  font-size: 14px !important;
  border-radius: 0;
  margin-left: 2px;
  margin-right: 2px;
  width: 90px;
  height: 35px !important;
  border-color: #76a7dc !important;
  border-top-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
  /* border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important; */
  /* border: 1px solid #76A7DC !important; */
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  /* color: rgba(255, 255, 255, 0.1) !important;
  background: rgba(79, 92, 115, 0.6) !important;  */
  /* color: rgba(255, 255, 255, 0.3) !important;
  background:  #4F5C73 !important; */
  transition: background-color 0.3s, color 0.3s, border-color 0.3s !important;
}

.btn-custom-individual-scope2-light {
  font-size: 14px !important;
  width: 90px;
  border-radius: 0;
  margin-left: 2px;
  margin-right: 2px;
  height: 35px !important;
  border-top-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
  /* border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important; */
  border-bottom: 1px solid #c6c6c6;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  color: #ffffff !important;
  background: #198754 !important;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s !important;
}

.btn-custom-individual-scope2-light-inactive {
  font-size: 14px !important;
  width: 90px;
  border-radius: 0;
  margin-left: 2px;
  margin-right: 2px;
  height: 35px !important;
  border-top-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
  /* border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important; */
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  color: #fff !important;
  background: #c6c6c6 !important;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s !important;
}

.btn-custom-individual-scope3 {
  font-size: 14px !important;
  width: 90px;
  height: 35px !important;
  border-top-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
  /* border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important; */
  border-bottom: 1px solid #c6c6c6;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  color: #c6c6c6 !important;
  background: linear-gradient(65deg, #101841, #202b60);
  transition: background-color 0.3s, color 0.3s, border-color 0.3s !important;
}

.btn-custom-individual-scope3-inactive {
  font-size: 14px !important;
  width: 90px;
  height: 35px !important;
  border-color: #76a7dc !important;
  border-top-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
  /* border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important; */
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  /* color: rgba(255, 255, 255, 0.3) !important;
  background:  #4F5C73 !important; */
  transition: background-color 0.3s, color 0.3s, border-color 0.3s !important;
}

.btn-custom-individual-scope3-light {
  font-size: 14px !important;
  width: 90px;
  height: 35px !important;
  border-top-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
  /* border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important; */
  border-bottom: 1px solid #c6c6c6;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  color: #ffffff !important;
  background: #198754 !important;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s !important;
}

.btn-custom-individual-scope3-light-inactive {
  font-size: 14px !important;
  width: 90px;
  height: 35px !important;
  border-top-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
  /* border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important; */
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  color: #fff !important;
  background: #c6c6c6 !important;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s !important;
}

.btn-custom-individual {
  font-size: 14px !important;
  width: 80px;
  height: 30.49px !important;
  border-color: #76a7dc !important;
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
  border: 1px solid #76a7dc !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  color: #222b5d !important;
  background-color: #76a7dc !important;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s !important;
}

.btn-custom-individual:hover {
  background-color: #76a7dc !important;
  color: #222b5d !important;
  border-color: #76a7dc !important;
}

.btn-custom-individual-inactive {
  font-size: 14px !important;
  width: 80px;
  height: 30.49px !important;
  border-color: #76a7dc !important;
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
  border: 1px solid #76a7dc !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  color: #76a7dc !important;
  background-color: transparent !important;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s !important;
}

.btn-custom-individual-inactive:hover {
  background-color: #76a7dc !important;
  color: #222b5d !important;
  border-color: #76a7dc !important;
}

.btn-custom-individual-light {
  font-size: 14px !important;
  width: 80px;
  height: 30.49px !important;
  border-color: #198754 !important;
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
  border: 1px solid #198754 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  color: #fff !important;
  background-color: #198754 !important;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s !important;
}

.btn-custom-individual-light:hover {
  background-color: #198754 !important;
  color: #fff !important;
  border-color: #198754 !important;
}

.btn-custom-individual-light-inactive {
  font-size: 14px !important;
  width: 80px;
  height: 30.49px !important;
  border-color: #198754 !important;
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
  border: 1px solid #198754 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  color: #198754 !important;
  background-color: transparent !important;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s !important;
}

.btn-custom-individual-light-inactive:hover {
  background-color: #198754 !important;
  color: #fff !important;
  border-color: #198754 !important;
}

.btn-custom-comparison {
  font-size: 14px !important;
  width: 120px !important;
  height: 30.49px !important;
  border-color: #76a7dc !important;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
  border: 1px solid #76a7dc !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  color: #222b5d !important;
  background-color: #76a7dc !important;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s !important;
}

.btn-custom-comparison:hover {
  background-color: #76a7dc !important;
  color: #222b5d !important;
  border-color: #76a7dc !important;
}

.btn-custom-comparison-inactive {
  font-size: 14px !important;
  width: 120px !important;
  height: 30.49px !important;
  border-color: #76a7dc !important;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
  border: 1px solid #76a7dc !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  color: #76a7dc !important;
  background-color: transparent !important;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s !important;
}

.btn-custom-comparison-inactive:hover {
  background-color: #76a7dc !important;
  color: #222b5d !important;
  border-color: #76a7dc !important;
}

.btn-custom-comparison-light {
  font-size: 14px !important;
  width: 120px !important;
  height: 30.49px !important;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
  border-color: #198754 !important;
  border: 1px solid #198754 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  color: #fff !important;
  background-color: #198754 !important;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s !important;
}

.btn-custom-comparison-light:hover {
  background-color: #198754 !important;
  color: #fff !important;
  border-color: #198754 !important;
}

.btn-custom-comparison-light-inactive {
  font-size: 14px !important;
  width: 120px !important;
  height: 30.49px !important;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
  border-color: #198754 !important;
  border: 1px solid #198754 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  color: #198754 !important;
  background-color: transparent !important;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s !important;
}

.btn-custom-comparison-light-inactive:hover {
  background-color: #198754 !important;
  color: #fff !important;
  border-color: #198754 !important;
}

.btn-custom-profileNOCanBus {
  font-size: 14px !important;
  width: 60px !important;
  height: 30.49px !important;
  border-color: #76a7dc !important;
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
  /* border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important; */
  border: 1px solid #76a7dc !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  color: #222b5d !important;
  background-color: #76a7dc !important;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s !important;
}

.btn-custom-profileNOCanBus:hover {
  background-color: #76a7dc !important;
  color: #222b5d !important;
  border-color: #76a7dc !important;
}

.btn-custom-profileNOCanBus-inactive {
  font-size: 14px !important;
  width: 60px !important;
  height: 30.49px !important;
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
  border: 1px solid #76a7dc !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  color: #76a7dc !important;
  background-color: transparent !important;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s !important;
}

.btn-custom-profileNOCanBus-inactive:hover {
  background-color: #76a7dc !important;
  color: #222b5d !important;
  border-color: #76a7dc !important;
}

.btn-custom-profileNOCanBus-light {
  font-size: 14px !important;
  width: 60px !important;
  height: 30.49px !important;
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
  border: 1px solid #198754 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  color: #fff !important;
  background-color: #198754 !important;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s !important;
}

.btn-custom-profileNOCanBus-light:hover {
  background-color: #198754 !important;
  color: #fff !important;
  border-color: #198754 !important;
}

.btn-custom-profileNOCanBus-light-inactive {
  font-size: 14px !important;
  width: 60px !important;
  height: 30.49px !important;
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
  border: 1px solid #198754 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  color: #198754 !important;
  background-color: transparent !important;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s !important;
}

.btn-custom-profileNOCanBus-light-inactive:hover {
  background-color: #198754 !important;
  color: #fff !important;
  border-color: #198754 !important;
}

.btn-custom-profile {
  font-size: 14px !important;
  width: 60px !important;
  height: 30.49px !important;
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
  border: 1px solid #76a7dc !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  color: #222b5d !important;
  background-color: #76a7dc !important;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s !important;
}

.btn-custom-profile:hover {
  background-color: #76a7dc !important;
  color: #222b5d !important;
  border-color: #76a7dc !important;
}

.btn-custom-profile-inactive {
  font-size: 14px !important;
  width: 60px !important;
  height: 30.49px !important;
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
  border: 1px solid #76a7dc !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  color: #76a7dc !important;
  background-color: transparent !important;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s !important;
}

.btn-custom-profile-inactive:hover {
  background-color: #76a7dc !important;
  color: #222b5d !important;
  border-color: #76a7dc !important;
}

.btn-custom-profile-light {
  font-size: 14px !important;
  width: 60px !important;
  height: 30.49px !important;
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
  border: 1px solid #198754 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  color: #fff !important;
  background-color: #198754 !important;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s !important;
}

.btn-custom-profile-light:hover {
  background-color: #198754 !important;
  color: #fff !important;
  border-color: #198754 !important;
}

.btn-custom-profile-light-inactive {
  font-size: 14px !important;
  width: 60px !important;
  height: 30.49px !important;
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
  border: 1px solid #198754 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  color: #198754 !important;
  background-color: transparent !important;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s !important;
}

.btn-custom-profile-light-inactive:hover {
  background-color: #198754 !important;
  color: #fff !important;
  border-color: #198754 !important;
}

.btn-custom-details {
  font-size: 14px !important;
  width: 60px !important;
  height: 30.49px !important;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
  border: 1px solid #76a7dc !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  color: #222b5d !important;
  background-color: #76a7dc !important;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s !important;
}

.btn-custom-details {
  background-color: #76a7dc !important;
  color: #222b5d !important;
  border-color: #76a7dc !important;
}

.btn-custom-details-inactive {
  font-size: 14px !important;
  width: 60px !important;
  height: 30.49px !important;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
  border: 1px solid #76a7dc !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  color: #76a7dc !important;
  background-color: transparent !important;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s !important;
}

.btn-custom-details-inactive:hover {
  background-color: #76a7dc !important;
  color: #222b5d !important;
  border-color: #76a7dc !important;
}

.btn-custom-details-light {
  font-size: 14px !important;
  width: 60px !important;
  height: 30.49px !important;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
  border: 1px solid #198754 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  color: #fff !important;
  background-color: #198754 !important;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s !important;
}

.btn-custom-details-light:hover {
  background-color: #198754 !important;
  color: #fff !important;
  border-color: #198754 !important;
}

.btn-custom-details-light-inactive {
  font-size: 14px !important;
  width: 60px !important;
  height: 30.49px !important;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
  border: 1px solid #198754 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  color: #198754 !important;
  background-color: transparent !important;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s !important;
}

.btn-custom-details-light-inactive:hover {
  background-color: #198754 !important;
  color: #fff !important;
  border-color: #198754 !important;
}

.btn-custom-monthlyNOCanBus {
  font-size: 14px !important;
  width: 60px !important;
  height: 30.49px !important;
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
  border: 1px solid #76a7dc !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  color: #222b5d !important;
  background-color: #76a7dc !important;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s !important;
}

.btn-custom-monthlyNOCanBus:hover {
  background-color: #76a7dc !important;
  color: #222b5d !important;
  border-color: #76a7dc !important;
}

.btn-custom-monthlyNOCanBus-inactive {
  font-size: 14px !important;
  width: 60px !important;
  height: 30.49px !important;
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
  border: 1px solid #76a7dc !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  color: #76a7dc !important;
  background-color: transparent !important;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s !important;
}

.btn-custom-monthlyNOCanBus-inactive:hover {
  background-color: #76a7dc !important;
  color: #222b5d !important;
  border-color: #76a7dc !important;
}

.btn-custom-monthlyNOCanBus-light {
  font-size: 14px !important;
  width: 60px !important;
  height: 30.49px !important;
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
  border: 1px solid #198754 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  color: #fff !important;
  background-color: #198754 !important;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s !important;
}

.btn-custom-monthlyNOCanBus-light:hover {
  background-color: #198754 !important;
  color: #fff !important;
  border-color: #198754 !important;
}

.btn-custom-monthlyNOCanBus-light-inactive {
  font-size: 14px !important;
  width: 60px !important;
  height: 30.49px !important;
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
  border: 1px solid #198754 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  color: #198754 !important;
  background-color: transparent !important;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s !important;
}

.btn-custom-monthlyNOCanBus-light-inactive:hover {
  background-color: #198754 !important;
  color: #fff !important;
  border-color: #198754 !important;
}

.btn-custom-daily {
  font-size: 14px !important;
  width: 60px !important;
  height: 30.49px !important;
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
  border: 1px solid #76a7dc !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  color: #222b5d !important;
  background-color: #76a7dc !important;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s !important;
}

.btn-custom-daily:hover {
  background-color: #76a7dc !important;
  color: #222b5d !important;
  border-color: #76a7dc !important;
}

.btn-custom-daily-inactive {
  font-size: 14px !important;
  width: 60px !important;
  height: 30.49px !important;
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
  border: 1px solid #76a7dc !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  color: #76a7dc !important;
  background-color: transparent !important;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s !important;
}

.btn-custom-daily-inactive:hover {
  background-color: #76a7dc !important;
  color: #222b5d !important;
  border-color: #76a7dc !important;
}

.btn-custom-daily-light {
  font-size: 14px !important;
  width: 60px !important;
  height: 30.49px !important;
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
  border: 1px solid #198754 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  color: #fff !important;
  background-color: #198754 !important;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s !important;
}

.btn-custom-daily-light:hover {
  background-color: #198754 !important;
  color: #fff !important;
  border-color: #198754 !important;
}

.btn-custom-daily-light-inactive {
  font-size: 14px !important;
  width: 60px !important;
  height: 30.49px !important;
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
  border: 1px solid #198754 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  color: #198754 !important;
  background-color: transparent !important;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s !important;
}

.btn-custom-daily-light-inactive:hover {
  background-color: #198754 !important;
  color: #fff !important;
  border-color: #198754 !important;
}

.btn-custom-monthly {
  font-size: 14px !important;
  width: 60px !important;
  height: 30.49px !important;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
  border: 1px solid #76a7dc !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  color: #222b5d !important;
  background-color: #76a7dc !important;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s !important;
}

.btn-custom-monthly {
  background-color: #76a7dc !important;
  color: #222b5d !important;
  border-color: #76a7dc !important;
}

.btn-custom-monthly-inactive {
  font-size: 14px !important;
  width: 60px !important;
  height: 30.49px !important;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
  border: 1px solid #76a7dc !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  color: #76a7dc !important;
  background-color: transparent !important;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s !important;
}

.btn-custom-monthly-inactive:hover {
  background-color: #76a7dc !important;
  color: #222b5d !important;
  border-color: #76a7dc !important;
}

.btn-custom-monthly-light {
  font-size: 14px !important;
  width: 60px !important;
  height: 30.49px !important;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
  border: 1px solid #198754 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  color: #fff !important;
  background-color: #198754 !important;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s !important;
}

.btn-custom-monthly-light:hover {
  background-color: #198754 !important;
  color: #fff !important;
  border-color: #198754 !important;
}

.btn-custom-monthly-light-inactive {
  font-size: 14px !important;
  width: 60px !important;
  height: 30.49px !important;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
  border: 1px solid #198754 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  color: #198754 !important;
  background-color: transparent !important;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s !important;
}

.btn-custom-monthly-light-inactive:hover {
  background-color: #198754 !important;
  color: #fff !important;
  border-color: #198754 !important;
}

.btn-custom-editUserAdmin {
  font-size: 16px !important;
  width: 90px !important;
  height: 37.24px !important;
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
  border: 1px solid #76a7dc !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  color: #222b5d !important;
  background-color: #76a7dc !important;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s !important;
}

.btn-custom-editUserAdmin:hover {
  background-color: #76a7dc !important;
  color: #222b5d !important;
  border-color: #76a7dc !important;
}

.btn-custom-editUserAdmin-inactive {
  font-size: 16px !important;
  width: 90px !important;
  height: 37.24px !important;
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
  border: 1px solid #76a7dc !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  color: #76a7dc !important;
  background-color: transparent !important;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s !important;
}

.btn-custom-editUserAdmin-inactive:hover {
  background-color: #76a7dc !important;
  color: #222b5d !important;
  border-color: #76a7dc !important;
}

.btn-custom-editUserAdmin-light {
  font-size: 16px !important;
  width: 90px !important;
  height: 37.24px !important;
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
  border: 1px solid #198754 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  color: #fff !important;
  background-color: #198754 !important;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s !important;
}

.btn-custom-editUserAdmin-light:hover {
  background-color: #198754 !important;
  color: #fff !important;
  border-color: #198754 !important;
}

.btn-custom-editUserAdmin-light-inactive {
  font-size: 16px !important;
  width: 90px !important;
  height: 37.24px !important;
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
  border: 1px solid #198754 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  color: #198754 !important;
  background-color: transparent !important;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s !important;
}

.btn-custom-editUserAdmin-light-inactive:hover {
  background-color: #198754 !important;
  color: #fff !important;
  border-color: #198754 !important;
}

.btn-custom-editUser {
  font-size: 16px !important;
  width: 90px !important;
  height: 37.24px !important;
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
  border: 1px solid #76a7dc !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  color: #222b5d !important;
  background-color: #76a7dc !important;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s !important;
}

.btn-custom-editUser:hover {
  background-color: #76a7dc !important;
  color: #222b5d !important;
  border-color: #76a7dc !important;
}

.btn-custom-editUser-inactive {
  font-size: 16px !important;
  width: 90px !important;
  height: 37.24px !important;
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
  border: 1px solid #76a7dc !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  color: #76a7dc !important;
  background-color: transparent !important;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s !important;
}

.btn-custom-editUser-inactive:hover {
  background-color: #76a7dc !important;
  color: #222b5d !important;
  border-color: #76a7dc !important;
}

.btn-custom-editUser-light {
  font-size: 16px !important;
  width: 90px !important;
  height: 37.24px !important;
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
  border: 1px solid #198754 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  color: #fff !important;
  background-color: #198754 !important;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s !important;
}

.btn-custom-editUser-light:hover {
  background-color: #198754 !important;
  color: #fff !important;
  border-color: #198754 !important;
}

.btn-custom-editUser-light-inactive {
  font-size: 16px !important;
  width: 90px !important;
  height: 37.24px !important;
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
  border: 1px solid #198754 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  color: #198754 !important;
  background-color: transparent !important;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s !important;
}

.btn-custom-editUser-light-inactive:hover {
  background-color: #198754 !important;
  color: #fff !important;
  border-color: #198754 !important;
}

.btn-custom-assignUnits {
  font-size: 16px !important;
  width: 115px !important;
  height: 37.24px !important;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
  border: 1px solid #76a7dc !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  color: #222b5d !important;
  background-color: #76a7dc !important;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s !important;
}

.btn-custom-assignUnits {
  background-color: #76a7dc !important;
  color: #222b5d !important;
  border-color: #76a7dc !important;
}

.btn-custom-assignUnits-inactive {
  font-size: 16px !important;
  width: 115px !important;
  height: 37.24px !important;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
  border: 1px solid #76a7dc !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  color: #76a7dc !important;
  background-color: transparent !important;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s !important;
}

.btn-custom-assignUnits-inactive:hover {
  background-color: #76a7dc !important;
  color: #222b5d !important;
  border-color: #76a7dc !important;
}

.btn-custom-assignUnits-light {
  font-size: 16px !important;
  width: 115px !important;
  height: 37.24px !important;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
  border: 1px solid #198754 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  color: #fff !important;
  background-color: #198754 !important;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s !important;
}

.btn-custom-assignUnits-light:hover {
  background-color: #198754 !important;
  color: #fff !important;
  border-color: #198754 !important;
}

.btn-custom-assignUnits-light-inactive {
  font-size: 16px !important;
  width: 115px !important;
  height: 37.24px !important;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
  border: 1px solid #198754 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  color: #198754 !important;
  background-color: transparent !important;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s !important;
}

.btn-custom-assignUnits-light-inactive:hover {
  background-color: #198754 !important;
  color: #fff !important;
  border-color: #198754 !important;
}

/* tracking file export modal download btn */

.btn-custom-excel {
  width: 50 !important;
  height: 100 !important;
  display: flex !important;
  align-items: center !important;
  background-color: #76a7dc !important;
}

.btn-custom-excel-light {
  height: 100 !important;
  display: flex !important;
  align-items: center !important;
  background-color: #198754 !important;
}
.light-theme .ant-picker-input > input::placeholder {
  color: #000;
}

.light-theme .ant-picker-input > input {
  color: #333;
}

.light-theme .ant-picker-range > .ant-picker-active-bar {
  background-color: green;
}

.dark-theme .ant-picker-input > input::placeholder {
  color: #999;
}

.dark-theme .ant-picker-input > input {
  color: #fff;
}

.dark-theme .ant-picker-input > .ant-picker-suffix {
  color: #999;
}

.dark-theme .ant-picker-input > .ant-picker-clear {
  color: #999;
  background-color: #222b5d;
}

.dark-theme div > .ant-picker-separator {
  color: #999;
  background-color: #222b5d;
}

.dark-theme .ant-picker-range > .ant-picker-suffix {
  color: #999;
}

.dark-theme .ant-picker-range > .ant-picker-clear {
  color: #999;
  background-color: #222b5d;
}

.dark-theme .ant-picker-range > .ant-picker-active-bar {
  background-color: #fff;
}

.loader {
  border-top: 4px solid transparent;
  width: 10px;
  height: 10px;
  animation: spin 2s ease-out infinite;
}

/* Define the spin animation */
@keyframes spin {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0.3;
  }
  50% {
    opacity: 0.5;
  }
  75% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}

/* Close Button */
.btn-close {
  padding: 5px;
  color: #fff; /* Close button color */
}

/* Close Button on Hover */
.btn-close:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.2); /* Hover background color */
}

/* Time Stamp */
.text-muted {
  font-size: 12px;
}
.ant-picker-panels > *:first-child button.ant-picker-header-next-btn {
  visibility: visible !important;
}

.ant-picker-panels > *:first-child button.ant-picker-header-super-next-btn {
  visibility: visible !important;
}

.ant-picker-panels > *:last-child {
  display: none !important;
}

.ant-picker-panel-container,
.ant-picker-footer {
  width: 280px !important;
}

.ant-picker-footer-extra > div {
  flex-wrap: wrap !important;
}

/* Add this CSS to hide the week number column */
.ant-calendar-table .ant-calendar-week-number-column {
  display: none !important;
}
.ant-picker-cell-week {
  display: none !important;
}

/* Add this CSS to hide the empty cell in the DatePicker header */
.ant-picker-header th[aria-label="empty cell"] {
  display: none !important;
}

.ant-picker-week-panel th[aria-label="empty cell"] {
  display: none !important;
}

/* custom-toast css */
.toast {
  background-color: var(--toast-bg-color-fallback);
  padding: var(--toast-padding);
  box-shadow: hsl(var(--secondary-hue) 10% 10% / 10%) 0px 1px 3px 0px,
    hsl(var(--secondary-hue) 10% 10% / 5%) 0px 1px 2px 0px;
  border-radius: 0.5em;
  position: relative;
  color: var(--toast-text-color-fallback);
  opacity: var(--toast-opacity);
  /* border-color: #00a3fe !important; */
}

.toast:hover {
  opacity: 1;
  box-shadow: hsl(var(--secondary-hue) 10% 10% / 30%) 0px 1px 3px 0px,
    hsl(var(--secondary-hue) 10% 10% / 15%) 0px 1px 2px 0px;
}

.toast + .toast {
  margin-top: var(--toast-margin);
}

:root {
  --toast-speed: 250ms;
  --toast-list-scrollbar-width: 0.35em;
  --toast-list-width: 400px;
  --toast-list-padding: 1em;
}

.toast-list {
  position: fixed;
  padding: var(--toast-list-padding);
  width: 100%;
  max-width: var(--toast-list-width);
  max-height: 100vh;
  overflow: hidden auto;
}

.toast-list--top-left {
  top: 0;
  left: 0;
}
.toast-list--top-right {
  top: 0;
  right: 0;
}
.toast-list--bottom-left {
  bottom: 0;
  left: 0;
}
.toast-list--bottom-right {
  bottom: 0;
  right: 0;
}

@keyframes toast-in-right {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes toast-in-left {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

.toast-list--top-left .toast,
.toast-list--bottom-left .toast {
  animation: toast-in-left var(--toast-speed);
}

.toast-list--top-right .toast,
.toast-list--bottom-right .toast {
  animation: toast-in-right var(--toast-speed);
}

.toast-list .toast {
  transition: transform var(--toast-speed), opacity var(--toast-speed),
    box-shadow var(--toast-speed) ease-in-out;
}

/* CANBus and Manual */

.btn-custom-canbus {
  font-size: 14px !important;
  width: 70px !important;
  height: 30.49px !important;
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
  border: 1px solid #76a7dc !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  color: #222b5d !important;
  background-color: #76a7dc !important;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s !important;
}

.btn-custom-canbus:hover {
  background-color: #76a7dc !important;
  color: #222b5d !important;
  border-color: #76a7dc !important;
}

.btn-custom-canbus-inactive {
  font-size: 14px !important;
  width: 70px !important;
  height: 30.49px !important;
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
  border: 1px solid #76a7dc !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  color: #76a7dc !important;
  background-color: transparent !important;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s !important;
}

.btn-custom-canbus-inactive:hover {
  background-color: #76a7dc !important;
  color: #222b5d !important;
  border-color: #76a7dc !important;
}

.btn-custom-canbus-light {
  font-size: 14px !important;
  width: 70px !important;
  height: 30.49px !important;
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
  border: 1px solid #198754 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  color: #fff !important;
  background-color: #198754 !important;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s !important;
}

.btn-custom-canbus-light:hover {
  background-color: #198754 !important;
  color: #fff !important;
  border-color: #198754 !important;
}

.btn-custom-canbus-light-inactive {
  font-size: 14px !important;
  width: 70px !important;
  height: 30.49px !important;
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
  border: 1px solid #198754 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  color: #198754 !important;
  background-color: transparent !important;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s !important;
}

.btn-custom-canbus-light-inactive:hover {
  background-color: #198754 !important;
  color: #fff !important;
  border-color: #198754 !important;
}

.btn-custom-manual {
  font-size: 14px !important;
  width: 70px !important;
  height: 30.49px !important;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
  border: 1px solid #76a7dc !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  color: #222b5d !important;
  background-color: #76a7dc !important;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s !important;
}

.btn-custom-manual-inactive {
  font-size: 14px !important;
  width: 70px !important;
  height: 30.49px !important;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
  border: 1px solid #76a7dc !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  color: #76a7dc !important;
  background-color: transparent !important;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s !important;
}

.btn-custom-manual-inactive:hover {
  background-color: #76a7dc !important;
  color: #222b5d !important;
  border-color: #76a7dc !important;
}

.btn-custom-manual-light {
  font-size: 14px !important;
  width: 70px !important;
  height: 30.49px !important;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
  border: 1px solid #198754 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  color: #fff !important;
  background-color: #198754 !important;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s !important;
}

.btn-custom-manual-light:hover {
  background-color: #198754 !important;
  color: #fff !important;
  border-color: #198754 !important;
}

.btn-custom-manual-light-inactive {
  font-size: 14px !important;
  width: 70px !important;
  height: 30.49px !important;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
  border: 1px solid #198754 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  color: #198754 !important;
  background-color: transparent !important;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s !important;
}

.btn-custom-manual-light-inactive:hover {
  background-color: #198754 !important;
  color: #fff !important;
  border-color: #198754 !important;
}

/* .manuals_data_table {
  max-height: 200px !important;
} */

.threshold-wrapper {
  position: relative;
}

.threshold {
  border: 1px solid gray;
  border-radius: 6px;
  position: relative;
  padding-left: 15px;
  margin-right: 15px;
  line-height: 6ex;
  z-index: 1;
}

.threshold-label {
  position: absolute;
  top: -10px;
  z-index: 10;
  left: 2em;
  color: "white";
  padding: 0 4px;
  background: rgb(20, 30, 70);
}
.recharts-default-legend {
  padding-top: 2rem !important;
}

/* CO2 Loading */
.loader {
  font-size: 5px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  animation: mulShdSpin 1.1s infinite ease;
  transform: translateZ(0);
}
@keyframes mulShdSpin {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #666,
      1.8em -1.8em 0 0em rgba(102, 102, 102, 0.2),
      2.5em 0em 0 0em rgba(102, 102, 102, 0.2),
      1.75em 1.75em 0 0em rgba(102, 102, 102, 0.2),
      0em 2.5em 0 0em rgba(102, 102, 102, 0.2),
      -1.8em 1.8em 0 0em rgba(102, 102, 102, 0.2),
      -2.6em 0em 0 0em rgba(102, 102, 102, 0.5),
      -1.8em -1.8em 0 0em rgba(102, 102, 102, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(102, 102, 102, 0.7),
      1.8em -1.8em 0 0em #666, 2.5em 0em 0 0em rgba(102, 102, 102, 0.2),
      1.75em 1.75em 0 0em rgba(102, 102, 102, 0.2),
      0em 2.5em 0 0em rgba(102, 102, 102, 0.2),
      -1.8em 1.8em 0 0em rgba(102, 102, 102, 0.2),
      -2.6em 0em 0 0em rgba(102, 102, 102, 0.2),
      -1.8em -1.8em 0 0em rgba(102, 102, 102, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(102, 102, 102, 0.5),
      1.8em -1.8em 0 0em rgba(102, 102, 102, 0.7), 2.5em 0em 0 0em #666,
      1.75em 1.75em 0 0em rgba(102, 102, 102, 0.2),
      0em 2.5em 0 0em rgba(102, 102, 102, 0.2),
      -1.8em 1.8em 0 0em rgba(102, 102, 102, 0.2),
      -2.6em 0em 0 0em rgba(102, 102, 102, 0.2),
      -1.8em -1.8em 0 0em rgba(102, 102, 102, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(102, 102, 102, 0.2),
      1.8em -1.8em 0 0em rgba(102, 102, 102, 0.5),
      2.5em 0em 0 0em rgba(102, 102, 102, 0.7), 1.75em 1.75em 0 0em #666,
      0em 2.5em 0 0em rgba(102, 102, 102, 0.2),
      -1.8em 1.8em 0 0em rgba(102, 102, 102, 0.2),
      -2.6em 0em 0 0em rgba(102, 102, 102, 0.2),
      -1.8em -1.8em 0 0em rgba(102, 102, 102, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(102, 102, 102, 0.2),
      1.8em -1.8em 0 0em rgba(102, 102, 102, 0.2),
      2.5em 0em 0 0em rgba(102, 102, 102, 0.5),
      1.75em 1.75em 0 0em rgba(102, 102, 102, 0.7), 0em 2.5em 0 0em #666,
      -1.8em 1.8em 0 0em rgba(102, 102, 102, 0.2),
      -2.6em 0em 0 0em rgba(102, 102, 102, 0.2),
      -1.8em -1.8em 0 0em rgba(102, 102, 102, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(102, 102, 102, 0.2),
      1.8em -1.8em 0 0em rgba(102, 102, 102, 0.2),
      2.5em 0em 0 0em rgba(102, 102, 102, 0.2),
      1.75em 1.75em 0 0em rgba(102, 102, 102, 0.5),
      0em 2.5em 0 0em rgba(102, 102, 102, 0.7), -1.8em 1.8em 0 0em #666,
      -2.6em 0em 0 0em rgba(102, 102, 102, 0.2),
      -1.8em -1.8em 0 0em rgba(102, 102, 102, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(102, 102, 102, 0.2),
      1.8em -1.8em 0 0em rgba(102, 102, 102, 0.2),
      2.5em 0em 0 0em rgba(102, 102, 102, 0.2),
      1.75em 1.75em 0 0em rgba(102, 102, 102, 0.2),
      0em 2.5em 0 0em rgba(102, 102, 102, 0.5),
      -1.8em 1.8em 0 0em rgba(102, 102, 102, 0.7), -2.6em 0em 0 0em #666,
      -1.8em -1.8em 0 0em rgba(102, 102, 102, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(102, 102, 102, 0.2),
      1.8em -1.8em 0 0em rgba(102, 102, 102, 0.2),
      2.5em 0em 0 0em rgba(102, 102, 102, 0.2),
      1.75em 1.75em 0 0em rgba(102, 102, 102, 0.2),
      0em 2.5em 0 0em rgba(102, 102, 102, 0.2),
      -1.8em 1.8em 0 0em rgba(102, 102, 102, 0.5),
      -2.6em 0em 0 0em rgba(102, 102, 102, 0.7), -1.8em -1.8em 0 0em #666;
  }
}

/* carbon chart select */
.dark-dropdown-menu {
  background-color: #333;
  color: #fff;
}

.light-dropdown-menu {
  background-color: #fff !important;
  color: #333;
  border: 1px solid silver !important;
  margin-top: 3px !important;
}

.form-check-label {
  cursor: pointer !important;
}

.canvasjs-chart-credit {
  display: none !important;
}

.playlist-card {
  display: block;
  top: 0px;
  position: relative;
  /* max-width: 262px; */
  background-color: #f2f8f9;
  border-radius: 4px;
  padding: 32px 24px;
  margin: 12px;
  text-decoration: none;
  z-index: 0;
  overflow: hidden;
  border: 1px solid #f2f8f9;

  &:hover {
    transition: all 0.2s ease-out;
    box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
    top: -4px;
    border: 1px solid #cccccc;
    /* background-color: white;
    color: #cccccc !important; */
  }

  /* &:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: -16px;
    right: -16px;
    background: #00838d;
    height: 32px;
    width: 32px;
    border-radius: 32px;
    transform: scale(2);
    transform-origin: 50% 50%;
    transition: transform 0.15s ease-out;
  } */

  /* &:hover:before {
    transform: scale(2.15);
  } */
}

.recharts-default-legend {
  font-size: 15px;
}
.unitcount {
  margin-top: 4px;
  margin-left: 45px;
}

.unitcountResponsive {
  margin-top: 4px;
  margin-left: 50px;
  width: 250px;
}

.btn-unit {
  z-index: 0;
  font-size: 14px !important;
  width: 90px;
  height: 38px !important;
  border-radius: 5px;
  border: 1px solid grey;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  color: #c6c6c6 !important;
  background: #202b60;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s !important;
}

.btn-unit-light {
  z-index: 0;
  font-size: 14px !important;
  width: 90px;
  height: 38px !important;
  border-radius: 5px;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  color: #ffffff !important;
  background: #198754 !important;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s !important;
}

/* switch button */
.switch {
  position: relative;
  display: inline-block;
  width: 5px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 10px;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* background-color: #ccc; */
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

/* input:checked + .slider {
  background-color: #2196F3;
} */

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
  width: 60px;
}

.slider.round:before {
  border-radius: 50%;
}

.toggle-container {
  position: relative;
}

.toggle-text {
  position: absolute;
  transition: transform 0.3s ease;
}

.dropdown-content {
  position: absolute;
  background-color: white;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  padding: 10px;
  border-radius: 4px;
}

.dropdown-content ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.dropdown-content li {
  padding: 8px 16px;
  cursor: pointer;
}

.dropdown-content li:hover {
  background-color: #f1f1f1;
}
/* start video guide */
.hvideo {
  height: 580px;
}
.video-player {
  margin-left: 50px;
  margin-top: 50px;
}
.video-playlist {
  font-size: 16px;
}

/* end video guide */

@media only screen and (min-width: 1400px) {
  .row-pd {
    padding-left: 300px !important;
  }
}

@media only screen and (max-width: 800px) {
  .units-pd {
    padding-left: 35px;
  }
  .video-player {
    margin-left: 0px;
    margin-top: 20px;
  }
  .video-playlist {
    font-size: 10px;
  }
  .hvideo {
    height: 300px;
  }
}

/* .timeline {
  list-style: none;
  padding: 0;
  position: relative;
}

.timeline::before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  width: 2px;
  background: #ccc;
  left: 20px;
  margin-left: -1.5px;
}

.timeline-item {
  margin-bottom: 20px;
  position: relative;
}

.timeline-item::before {
  content: '';
  position: absolute;
  left: 12px;
  top: 5px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #0A66C2;
  border: 2px solid #fff;
}

.timeline-content {
  margin-left: 40px;
  padding: 10px;
  background: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 4px;
}
 */
.accordion-item {
  border: "1px solid #E0E0E0";
}

.accordion-button {
  font-weight: bold;
  font-size: 1rem;
}

.month-list {
  max-height: 200px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.month-item {
  cursor: pointer;
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  transition: background-color 0.3s;
}

/* .month-item:hover {
  background-color: var(--background-color-dark);
} */

.month-item.active {
  /* font-weight: bold; */
  color: skyblue;
}

.month-indicator {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: transparent;
  margin-right: 0.5rem;
  transition: background-color 0.3s;
  color: white;
}

.month-item.active .month-indicator {
  background-color: #007bff;
}

.timeline {
  list-style: none;
  /* padding: 0; */
  position: relative;
  padding-left: 56px;
}

.timeline::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  width: 0.5px;
  background: #e0e0e0;
  left: 75px;
  /* margin-left: -1.5px; */
}

.timeline-item {
  position: relative;
  margin: 2rem 0;
  padding-left: 40px;
}

.timeline-item::before {
  content: "";
  position: absolute;
  left: 12px;
  top: 5px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #0a66c2;
  border: 1px solid #e0e0e0;
}

.timeline-item.active::before {
  background-color: #ff5733;
}

.timeline-content {
  padding-left: 1rem;
  border-radius: 4px;
  cursor: pointer;
}

.timeline-item-new {
  position: relative;
  margin: 2rem 0;
  padding-left: 40px;
}

.news-list {
  max-height: 550px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}
.news-lists {
  max-height: 650px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

text.segment-value:nth-child(1) {
  transform: rotate(180deg) translate(75px, -8px);
  display: block !important;
}

text.segment-value {
  display: none;
}
text.segment-value:nth-child(4) {
  position: absolute;
  transform: rotate(0deg) translate(75px, 20px);
  display: block !important;
}

/* Input and selector */

.input-selector-container {
  background-color: #202b60;
  height: 38px;
  border-radius: 3px;
  border: 1px solid #ced4da;
  margin-bottom: 10px;
}

.input-selector-combo {
  height: 40px;
  display: flex;
  align-items: center;
  border: 1px solid #fff;
  border-radius: 4px;
  background-color: #202b60;
}

.input-half {
  height: 100%;
  flex: 1;
  padding: 8px;
  border: none;
  border-radius: 3px;
  outline: none;
  background-color: #202b60;
}

.selector-half {
  height: 100%;
  flex: 1;
  padding: 11px;
  border: none;
  border-radius: 3px;
  outline: none;
  background-color: #202b60;
  color: #fff;
  overflow: hidden;
}

.seperate-bar {
  align-self: stretch;
  width: 1px;
  background-color: hsl(0, 0%, 80%);
  margin-bottom: 8px;
  margin-top: 8px;
  box-sizing: border-box;
}

/* html, body, #root {
  height: 100%;
  margin: 0;
  padding: 0;
} */